import { useEffect, useState } from "react";
import { API } from "../API";
import Toast from "./Toast";

// const type = {
//   "2024_09": "2024_09",
// };

const DEEP_LINK = {
  HOME: "icolorapp://action?what=page?to=main",
  DIAGNOSIS: "icolorapp://action?what=page?to=magic/camera&retry_type=N",
  COLOR_FINDER:
    "icolorapp://action?what=page?to=colorFinder/camera&retry_type=N",
  ANYASK: "icolorapp://action?what=meeting?type=ASK",
  COMMUNITY: "icolorapp://action?what=meeting?type=COMMUNITY",
  FITTEM: "icolorapp://action?what=page?to=fitting/main",
  REVIEW_LIST: "icolorapp://action?what=page?to=review/webview",
  MY_PAGE: "icolorapp://action?what=page?to=mypage/myAccount",
  SEARCH: "icolorapp://action?what=page?to=search/home",
  PUSH_SETTING: "icolorapp://action?what=page?to=mypageSetting/pushAlarm",
};

// const DEEP_LINK_ARRAY = [
//   DEEP_LINK.HOME,
//   DEEP_LINK.DIAGNOSIS,
//   DEEP_LINK.COLOR_FINDER,
//   DEEP_LINK.ANYASK,
//   DEEP_LINK.COMMUNITY,
//   DEEP_LINK.FITTEM,
//   DEEP_LINK.REVIEW_LIST,
//   DEEP_LINK.MY_PAGE,
//   DEEP_LINK.SEARCH,
// ];

const MENU = [
  {
    name: "홈",
    link: DEEP_LINK.HOME,
    onBackground: "/images/firstAnniversaryEvent/menuButton/on/1.png",
    offBackground: "/images/firstAnniversaryEvent/menuButton/off/1.png",
  },
  {
    name: "퍼컬 진단",
    link: DEEP_LINK.DIAGNOSIS,
    onBackground: "/images/firstAnniversaryEvent/menuButton/on/2.png",
    offBackground: "/images/firstAnniversaryEvent/menuButton/off/2.png",
  },
  {
    name: "컬러파인더",
    link: DEEP_LINK.COLOR_FINDER,
    onBackground: "/images/firstAnniversaryEvent/menuButton/on/3.png",
    offBackground: "/images/firstAnniversaryEvent/menuButton/off/3.png",
  },
  {
    name: "무불보",
    link: DEEP_LINK.ANYASK,
    onBackground: "/images/firstAnniversaryEvent/menuButton/on/4.png",
    offBackground: "/images/firstAnniversaryEvent/menuButton/off/4.png",
  },
  {
    name: "커뮤니티",

    link: DEEP_LINK.COMMUNITY,
    onBackground: "/images/firstAnniversaryEvent/menuButton/on/5.png",
    offBackground: "/images/firstAnniversaryEvent/menuButton/off/5.png",
  },
  {
    name: "피팅템",
    link: DEEP_LINK.FITTEM,
    onBackground: "/images/firstAnniversaryEvent/menuButton/on/6.png",
    offBackground: "/images/firstAnniversaryEvent/menuButton/off/6.png",
  },
  {
    name: "리뷰",
    link: DEEP_LINK.REVIEW_LIST,
    onBackground: "/images/firstAnniversaryEvent/menuButton/on/7.png",
    offBackground: "/images/firstAnniversaryEvent/menuButton/off/7.png",
  },
  {
    name: "마이페이지",
    link: DEEP_LINK.MY_PAGE,
    onBackground: "/images/firstAnniversaryEvent/menuButton/on/8.png",
    offBackground: "/images/firstAnniversaryEvent/menuButton/off/8.png",
  },
  {
    name: "검색",
    link: DEEP_LINK.SEARCH,
    onBackground: "/images/firstAnniversaryEvent/menuButton/on/9.png",
    offBackground: "/images/firstAnniversaryEvent/menuButton/off/9.png",
  },
];

function AnniversaryEvent() {
  // 나의 출석 현황
  // developtment access token eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyX2lkIjo5OTYyOTk3ODg3LCJ1c2VyX25hbWUiOiJoZW8iLCJpYXQiOjE3MTM5MjQ4MjMsImV4cCI6MTc0NTQ4MjQyMywiaXNzIjoiUk9NQU5EIn0.ISfTXbfVt5BMIKK3RFubLvKY_RZoibTRBL4maWLqeEc
  // pro access token eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyX2lkIjo5OTcyMDI2NjgyLCJ1c2VyX25hbWUiOiJ0ZXN0ZXIiLCJpYXQiOjE3MTAzMjQzMzAsImV4cCI6MTc0MTg4MTkzMCwiaXNzIjoiUk9NQU5EIn0.YDHAPWUEgoEiq14lFi6Dy6g5EAa6mav38TLO_ohRaYg

  const [needToPushPermission, setNeedToPushPermission] = useState(false);
  const [eventState, setEventState] = useState(false);
  const [menuClicked, setMenuClicked] = useState("");
  const [alarmAnimate, setAlarmAnimate] = useState(false);
  const params = new URLSearchParams(window.location.search);
  const accessToken = params.get("accessToken");
  const env = params.get("env") || "production";

  // 스탬프 판 위치, 알람, 폰번호 확인 버튼 위치
  const MENU_BUTTON_TOP = !eventState ? "top-[66.13%]" : "top-[68.2%]";
  const SURVEY_TOP = !eventState ? "top-[75.2%]" : "top-[77.5%]";
  const ALARM_TOP = "top-[88.43%]";

  // const accessToken =
  //   "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyX2lkIjo5OTYyOTk3ODg3LCJ1c2VyX25hbWUiOiJoZW8iLCJpYXQiOjE3MTM5MjQ4MjMsImV4cCI6MTc0NTQ4MjQyMywiaXNzIjoiUk9NQU5EIn0.ISfTXbfVt5BMIKK3RFubLvKY_RZoibTRBL4maWLqeEc";
  // const env = params.get("env") || "development";

  // find by query parameter
  const [platform, setPlatform] = useState("");

  function getPlatform() {
    const userAgent = window.navigator.userAgent,
      platform = window.navigator?.userAgentData?.platform,
      macosPlatforms = ["macOS", "Macintosh", "MacIntel", "MacPPC", "Mac68K"],
      iosPlatforms = ["iPhone", "iPad", "iPod"];
    setPlatform("IOS");

    if (
      macosPlatforms.indexOf(platform) !== -1 ||
      iosPlatforms.indexOf(platform) !== -1
    ) {
      // os = "IOS";
      setPlatform("IOS");
      return;
    } else if (/Android/.test(userAgent)) {
      // os = "ANDROID";
      setPlatform("ANDROID");
      return;
    }
  }

  useEffect(() => {
    getPlatform();
  }, []);

  // useEffect(() => {
  //   console.log("OS", platform);
  //   console.log("isEventPush", eventState);
  //   console.log("needToPushPermission", needToPushPermission);
  // }, [platform, eventState, needToPushPermission]);
  function handlePushPermission() {
    window.ReactNativeWebView.postMessage(
      JSON.stringify({
        type: "deeplink",
        value: DEEP_LINK.PUSH_SETTING,
      })
    );
  }

  // async function handlePushPermission() {
  //   if (needToPushPermission) {
  //     window.ReactNativeWebView.postMessage(
  //       JSON.stringify({
  //         type: "deeplink",
  //         value: DEEP_LINK.PUSH_SETTING,
  //       })
  //     );
  //   } else {
  //     if (env != null && accessToken != null && platform !== "") {
  //       try {
  //         setAlarmAnimate(true);
  //         const pushSetting = {
  //           platformType: platform,
  //           state: {
  //             communicationState: true,
  //             attendanceState: true,
  //             eventState: true,
  //             lateNightState: true,
  //           },
  //         };
  //         const r = await API.updatePushSetting(
  //           env,
  //           accessToken,
  //           platform,
  //           pushSetting
  //         );
  //         // toHaveStyle;
  //         if (r.status === 200) {
  //           setEventState(true);
  //           window?.ReactNativeWebView?.postMessage(
  //             JSON.stringify({
  //               type: "toast-info",
  //               value: `이벤트 푸시 알림을 허용했어요.`,
  //             })
  //           );
  //         }
  //         console.log(r.data);
  //       } catch (err) {
  //         console.log(err);
  //         sendError(err);
  //       }
  //     }
  //   }
  // }
  // 메뉴 버튼 클릭시
  function handleClickMenuButton(menu) {
    // console.log("menu.link", menu.link);
    setMenuClicked(menu.name);
    window.ReactNativeWebView.postMessage(
      JSON.stringify({
        type: "deeplink",
        value: menu.link,
      })
    );
  }

  useEffect(() => {
    // fetch user's push setting
    const fetchPushSetting = async () => {
      if (env != null && accessToken != null && platform !== "") {
        try {
          const { data } = await API.getPushSetting(env, accessToken, platform);

          if (!data) {
            setNeedToPushPermission(true);
          } else {
            setEventState(data.eventState);
          }
        } catch (err) {
          console.log(err);
          setNeedToPushPermission(err?.response?.status === 404);
        }
      }
    };
    fetchPushSetting();
    console.log("needToPushPermission", needToPushPermission);
  }, [accessToken, env, platform]);

  console.log("platform", platform);

  function sendHeightValue() {
    const height = document.body.scrollHeight;
    const data = { type: "height", value: height };
    window?.ReactNativeWebView?.postMessage(JSON.stringify(data));
  }

  function sendError(error) {
    window?.ReactNativeWebView?.postMessage(
      JSON.stringify({
        type: "toast-error",
        value:
          error?.response?.data?.message ||
          error?.message ||
          "알 수 없는 오류가 발생했습니다.",
      })
    );
  }

  // receive message from app
  useEffect(() => {
    setTimeout(() => {
      sendHeightValue();
    }, 1500);
  }, []);
  // 날짜 시간 함수
  // function getCurrentDateTime() {
  //   const date = new Date();

  //   const year = date.getFullYear();
  //   const month = String(date.getMonth() + 1).padStart(2, "0"); // months are 0-based in JavaScript
  //   const day = String(date.getDate()).padStart(2, "0");
  //   const hours = String(date.getHours()).padStart(2, "0");
  //   const minutes = String(date.getMinutes()).padStart(2, "0");

  //   return `${year}.${month}.${day} ${hours}:${minutes}`;
  // }

  if (!accessToken) return null;

  return (
    <main
      className="w-full flex justify-center select-none"
      onDragStart={(e) => {
        e.preventDefault();
      }}
      style={{
        userSelect: "none",
      }}
    >
      <section className={"max-w-[400px] relative"}>
        {/* <h1>1주년 이벤트 페이지</h1> */}
        <img
          src={"/images/firstAnniversaryEvent/background/1.jpg"}
          alt="Main Background"
          className={"w-full"}
        />

        <img
          className={"w-full"}
          src={"/images/firstAnniversaryEvent/background/2.jpg"}
          alt="Bottom Background"
        />
        <img
          className={"w-full"}
          src={"/images/firstAnniversaryEvent/background/3-1.jpg"}
          alt="Bottom Background2"
        />
        {!eventState ? (
          <img
            className={"w-full"}
            src={"/images/firstAnniversaryEvent/background/3-2.jpg"}
            alt="Bottom Background2"
          />
        ) : (
          <img
            className={"w-full"}
            src={"/images/firstAnniversaryEvent/background/3-3.jpg"}
            alt="Bottom Background2"
          />
        )}

        {/* 본문 */}
        {/* 메뉴 버튼들 */}
        <div
          className={`absolute  grid grid-rows-3 grid-cols-3 gap-2 ${MENU_BUTTON_TOP} mx-[12%] `}
        >
          {MENU.map((menu, index) => (
            <div key={index} onClick={() => handleClickMenuButton(menu)}>
              <img
                src={
                  menuClicked === menu.name
                    ? menu.onBackground
                    : menu.offBackground
                }
                alt={"survey button"}
                className={`w-full h-auto z-11 `}
              />
            </div>
          ))}
        </div>
        {/* 설문  참여하기 버튼 */}
        <a
          href={
            "icolorapp://action?what=openweb?url=https://moaform.com/q/8FTGhg"
          }
        >
          <img
            src={"/images/button/btn-push.png"}
            alt={"survey button"}
            className={`absolute w-[80%] mx-[12%] h-[80px] bg-[#6A9FFF] z-11 opacity-0 ${SURVEY_TOP}`}
          />
        </a>
        {/* 알림 받기 버튼 */}
        {/* <div onClick={() => console.log("클릭")}> */}
        {!eventState ? (
          <div
            onClick={handlePushPermission}
            className="flex items-center justify-center"
          >
            <img
              src={"/images/firstAnniversaryEvent/menuButton/1.png"}
              alt={"alarm button"}
              className={`absolute w-[75%] h-auto  z-11  ${ALARM_TOP}`}
            />
          </div>
        ) : null}
      </section>
    </main>
  );
}

export default AnniversaryEvent;
