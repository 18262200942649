// const host = process.env.REACT_APP_API_HOST + "/v2/event";
import axios from "axios";

function getHost(env) {
  if (env === "production") {
    return process.env.REACT_APP_API_HOST_PRODUCTION;
  } else if (env === "development") {
    return process.env.REACT_APP_API_HOST_DEVELOPMENT;
  } else {
    return process.env.REACT_APP_API_HOST_LOCAL;
  }
}

export const API = {
  getAttendance: (env, accessToken, type) => {
    return axios(`${getHost(env)}/v2/event/attendance?type=${type}`, {
      headers: { Authorization: `Bearer ${accessToken}` },
    });
  },

  attendance: (env, accessToken, type) => {
    // if (process.env.NODE_ENV === "development") {
    //   accessToken =
    //     "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyX2lkIjoxLCJ1c2VyX25hbWUiOiJ0ZXN0ZXIiLCJpYXQiOjE3MDgwOTYyNzUsImV4cCI6MTczOTY1Mzg3NSwiaXNzIjoiUk9NQU5EIn0.NdUWaVCvE9sBl946zzrfS2PiGDQ8EWAMdkizuBe5muA";
    // }
    return axios(`${getHost(env)}/v2/event/attendance?type=${type}`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
  },

  attendance2: (env, accessToken, type) => {
    // if (process.env.NODE_ENV === "development") {
    //   accessToken =
    //     "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyX2lkIjoxLCJ1c2VyX25hbWUiOiJ0ZXN0ZXIiLCJpYXQiOjE3MDgwOTYyNzUsImV4cCI6MTczOTY1Mzg3NSwiaXNzIjoiUk9NQU5EIn0.NdUWaVCvE9sBl946zzrfS2PiGDQ8EWAMdkizuBe5muA";
    // }
    return axios(`${getHost(env)}/v2/event/attendance/v2?type=${type}`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
  },

  getPushSetting: (env, accessToken, platform) => {
    return axios(`${getHost(env)}/v2/user/push-setting?platform=${platform}`, {
      headers: { Authorization: `Bearer ${accessToken}` },
    });
  },

  updatePushSetting: (env, accessToken, platform, pushSetting) => {
    return axios(`${getHost(env)}/v2/user/push-setting`, {
      data: pushSetting,
      method: "PATCH",
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
  },
};
