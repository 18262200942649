import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import AttendacneEvent from "./Attendance/AttendanceEvent";
import reportWebVitals from "./reportWebVitals";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import AttendanceEvent202405 from "./Attendance/AttendanceEvent-2024-05";
import AttendanceEvent202406 from "./Attendance/AttendanceEvent-2024-06";
import AttendanceEvent202407 from "./Attendance/AttendanceEvent-2024-07";
import AttendanceEvent202408 from "./Attendance/AttendanceEvent-2024-08";
import AttendanceEvent202409 from "./Attendance/AttendanceEvent-2024-09";
import AttendanceEvent202409Test from "./Attendance/AttendanceEvent-2024-09-test";
import FirstAnniversaryEvent from "./Event/FirstAnniversaryEvent";

const root = ReactDOM.createRoot(document.getElementById("root"));

const router = createBrowserRouter([
  {
    path: "/",
    element: <AttendacneEvent />,
  },
  {
    path: "/attendance",
    element: <AttendacneEvent />,
  },
  {
    path: "/attendance/2024/05",
    element: <AttendanceEvent202405 />,
  },
  {
    path: "/attendance/2024/06",
    element: <AttendanceEvent202406 />,
  },
  {
    path: "/attendance/2024/07",
    element: <AttendanceEvent202407 />,
  },
  {
    path: "/attendance/2024/08",
    element: <AttendanceEvent202408 />,
  },
  {
    path: "/attendance/2024/09",
    element: <AttendanceEvent202409 />,
  },
  {
    path: "/attendance/2024/09/test",
    element: <AttendanceEvent202409Test />,
  },
  {
    path: "/event/first-anniversary",
    element: <FirstAnniversaryEvent />,
  },
]);

root.render(<RouterProvider router={router} />);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
